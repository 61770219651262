export default class ConsentType {
  static component() {
    return null
  }

  constructor(id) {
    this._id = id
  }

  get id() {
    return this._id
  }
}
