import at from './at.json'
import bg from './bg.json'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fi from './fi.json'
import fr from './fr.json'
import he from './he.json'
import hu from './hu.json'
import it from './it.json'
import ko from './ko.json'
import lv from './lv.json'
import nl from './nl.json'
import pl from './pl.json'
import pt from './pt.json'
import ro from './ro.json'
import rs from './rs.json'
import ru from './ru.json'
import sk from './sk.json'
import sr from './sr.json'
import th from './th.json'
import tr from './tr.json'
import ua from './ua.json'

export default {
  at,
  bg,
  de,
  en,
  es,
  fi,
  fr,
  he,
  hu,
  it,
  ko,
  lv,
  nl,
  pl,
  pt,
  ro,
  rs,
  ru,
  sk,
  sr,
  th,
  tr,
  ua,
}
