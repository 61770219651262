const config = {
  hostMap: [
    // more specific host map entries should come first

    // "OLD Domain" which was exclusively build for BI (staging)
    {
      pattern: '^hello\\.staging\\.echo-app\\.io$',
      config: {
        clientName: 'bi',
        apiUrl: 'api.staging.echo-app.io/api/v1',
        callbackUrl: 'hello.staging.echo-app.io/callback',
      },
    },

    // "OLD Domain" which was exclusively build for BI (production)
    {
      pattern: '^hello\\.echo-app\\.io$',
      config: {
        clientName: 'bi',
        apiUrl: 'bi.api.one-message.app/api/v1',
        callbackUrl: 'hello.echo-app.io/callback',
      },
    },
    {
      pattern: '^om\\.boehringer-ingelheim\\.com$',
      config: {
        clientName: 'bi',
        apiUrl: 'bi.api.one-message.app/api/v1',
        callbackUrl: 'hello.echo-app.io/callback',
      },
    },

    // default host map, will try to identify the client based on the subdomain
    {
      pattern: process.env.VUE_APP_OM_CLIENT_HOST_PATTERN,
      config: {
        apiUrl: process.env.VUE_APP_OM_CLIENT_API_HOST,
        callbackUrl: process.env.VUE_APP_OM_CLIENT_CALLBACK_HOST,
      },
    },
  ],
}
export default config
