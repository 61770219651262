import router from '../router'
import { TOKEN_COOKIE_NAME } from '@/constants'
import { storeTokenToCookie } from '@/library/cookies'
import connectionConfig from '@/library/connectionConfig'
import { parseMultiParam } from '@/library/params'

/**
 * Loaders which will be executed to load a token
 * - executed in order
 * - first match wins
 *
 * @type {*}
 */
const TOKEN_LOADERS = [
  // hello.one-message.app/{token}
  () => {
    if (router.history.pending) {
      // pending = we have used our navigation guard to load the onboarding
      return router.history.pending.params.onboardingToken
    } else if (router.currentRoute) {
      // current route === we have loaded the loadToken view
      return router.currentRoute.params.onboardingToken
    }

    return null
  },

  // hello.one-message.app?t=xyz (and more)
  () => {
    const params = new URLSearchParams(location.search)
    if (params.has('t')) {
      return params.get('t')
    }
  },

  // parses multiparams (parameters separated by | encoded in base64 and urlencoded)
  // hello.one-message.app?state=token|something-else
  () => {
    const params = new URLSearchParams(location.search)

    if (params.has('state')) {
      return parseMultiParam(params.get('state'))[0] ?? null
    }

    return null
  },

  // uses the state parameter as it is, without using the multiparam idea
  // hello.one-message.app?state=token
  () => {
    const params = new URLSearchParams(location.search)

    if (params.has('state')) {
      return params.get('state')
    }

    return null
  },

  // cookie
  () => {
    try {
      return document.cookie
        .split('; ')
        .find(row => row.startsWith(TOKEN_COOKIE_NAME))
        .split('=')[1]
    } catch (err) {
      return null
    }
  },
]

const state = {
  // token = the token we've gathered from the parameters, url or cookie
  token: null,

  // well, the client identifier/name, e.g. bi, demo, etc.
  clientName: connectionConfig.clientName,
}

const getters = {
  token(state) {
    return state.token
  },
  clientName(state) {
    return state.clientName
  },
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token

    // token is stored in cookie to ensure that we can always refresh the onboarding
    storeTokenToCookie(token)
  },
}

const actions = {
  async loadToken({ commit }) {
    // go through token loaders
    const token = TOKEN_LOADERS.reduce((token, fn) => {
      if (token) {
        return token
      }

      return fn()
    }, null)

    return token
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
