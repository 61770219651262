<template>
  <div>
    <p>{{ $t('starting.waiting') }}</p>

    <div v-loading="true"></div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { deleteTokenCookie } from '@/library/cookies'
  import { ERROR_ONBOARDING_NOT_FOUND } from '@/errors'

  export default {
    name: 'LoadToken',
    methods: {
      ...mapActions('onboarding', ['fetch']),
    },

    async mounted() {
      try {
        await this.fetch()

        this.$router.push({ name: 'select-channel', query: this.$route.query })
      } catch (err) {
        deleteTokenCookie()

        this.$router
          .push({
            name: 'error',
            params: {
              errorMessage: ERROR_ONBOARDING_NOT_FOUND,
            },
          })
          .catch()

        throw err
      }
    },
  }
</script>
