import ca from 'element-ui/src/locale/lang/ca'

/**
 * Creates a parameter set
 *
 * @param params
 * @return {string}
 */
export function makeMultiParam(...params) {
  return encodeURIComponent(btoa(params.join('|')))
}

/**
 * Parses the state param
 *
 * @param param
 * @return {string[]}
 */
export function parseMultiParam(param) {
  try {
    return atob(decodeURIComponent(param)).split('|')
  } catch (err) {
    return []
  }
}
