import axios from 'axios'
import config from '../library/connectionConfig'

const api = axios.create({
  baseURL: config.apiUrl,
})

// default interceptors
api.interceptors.response.use(
  response => {
    // laravel resources mapping
    response.data = response.data.data

    return response
  },
  err => {
    throw err
  }
)

api.interceptors.request.use(
  config => {
    const x = Object.fromEntries(new URLSearchParams(location.search))

    if (!config.params) {
      config.params = {}
    }

    Object.assign(config.params, x)

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

export { api }
