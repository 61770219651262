<template>
  <div class="channel-selection" :class="cssClasses">
    <div class="channel-selection__page-header">
      <section class="channel-selection__header">
        <div class="channel-selection__logo" role="img" aria-label="Logo"></div>
        <p>
          {{ $t('channelSelection.choose') }}
        </p>
      </section>

      <div class="channel-selection__list">
        <mini-channel
          v-for="(channel, index) in channels"
          :key="index"
          :channel="channel"
          :class="{ selected: isSelectedChannel(channel) }"
          :cancelable="canClear"
          class="channel-selection__channel"
          @select="selectChannel"
          @validation-state-changed="onStepComponentValidationStateChanged(index, $event)"
        />
      </div>
    </div>

    <!-- step components -->
    <section v-if="shouldShowStepComponents" class="channel-selection__steps">
      <div
        :is="component"
        v-for="(component, index) in stepComponents"
        :key="index"
        ref="steps"
        :channel="selectedChannel"
        class="channel-selection__step"
        @validation-state-changed="onStepComponentValidationStateChanged(index, $event)"
      />
    </section>

    <!-- errors components -->
    <ul v-if="errors.length" class="channel-selection__errors">
      <li v-for="(error, index) in errors" :key="index" v-html="error.text"></li>
    </ul>

    <!-- buttons -->
    <section v-if="hasChannelSelected" class="channel-selection__buttons">
      <el-button v-if="stepComponents.length" type="primary" round @click="validate">
        {{ $t('finalization.continue') }}
      </el-button>
    </section>
  </div>
</template>

<style lang="stylus" scoped>
  .channel-selection
    p
      margin 0
      text-align center
      padding 16px
      line-height 1.6em

    &__selected-channel
      margin-top 32px

    &__header
      display flex
      flex-direction column
      align-items center
      padding-top 56px
      background var(--background-color)

      img
        max-width 200px
        display block

      p
        margin 48px 0 16px 0
        padding 0

    &__logo
      background-image: var(--logo-url);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 50px;
      width: 180px;
      margin-top: 30px;

    &__list
      padding 2px 16px

    // 16px

    &__channel
      margin 16px 0
      padding 16px
      background-color var(--container-background-color)
      border-radius 24px

    &__steps
      padding 0

    &__step
      margin-bottom 36px
      &:not(.legal-texts)
        padding 0 32px

    &__buttons
      text-align center
      padding 0 0 64px 0

    &__errors
      padding 0 48px
      margin-bottom 32px
      font-size 14px
      color var(--error)

    &.channel-selected
      // hide other channels

      .channel-selection__channel:not(.selected)
        display none

      .channel-selection__page-header
        margin-bottom 32px

      // update sizes

      .channel-selection__header
        padding 32px

        img
          max-width 140px

        p
          display none

      // the selected channel is "highlighted"

      .channel-selection__channel
        margin 0
        box-shadow 0 6px 22px -20px #000;
</style>

<script>
  import { mapGetters } from 'vuex'
  import LegalTexts from '../components/LegalTexts'
  import MiniChannel from '@/components/MiniChannel'
  import requiresOnboarding from '@/mixins/requiresOnboarding'
  import { EVENT_CHANNEL_SELECTED, EVENT_CONSENT_DATA_MISSING, trackEvent } from '@/library/tracker'

  const DEFAULT_STEPS = [LegalTexts]

  export default {
    name: 'SelectChannel',
    components: { MiniChannel, LegalTexts },
    mixins: [requiresOnboarding],

    data() {
      return {
        selectedChannelId: null,
        stepComponents: [LegalTexts],
        errors: [],
        theme: null,
      }
    },

    computed: {
      ...mapGetters('onboarding', ['channels', 'token']),
      ...mapGetters(['clientName']),

      selectedChannel() {
        return this.channels.find(c => c.id === this.selectedChannelId)
      },

      shouldShowStepComponents() {
        return this.hasChannelSelected && this.stepComponents.length > 0
      },

      hasChannelSelected() {
        return !!this.selectedChannel
      },

      cssClasses() {
        return this.hasChannelSelected ? 'channel-selected' : 'no-channel-selected'
      },

      canClear() {
        return this.hasChannelSelected && this.channels.length > 1
      },
    },
    watch: {
      async 'selectedChannel.id'(newChannelId) {
        if (!newChannelId) {
          return
        }

        if (!this.selectedChannel) {
          return
        }

        this.$loader(true)

        this.errors = []
        this.stepComponents = []

        if (this.selectedChannel.shouldGatherConsentsWithHelloApp()) {
          // adding defaults as we want to show the legal texts first
          this.stepComponents = (await this.selectedChannel.getChannelViews()).concat(DEFAULT_STEPS)
        }

        // we can continue right away if we dont have steps
        if (this.stepComponents.length === 0) {
          await this.selectedChannel.saveConsents()
          await this.$endOnboarding(this.selectedChannel)
        }

        this.$loader(false)
      },
      '$route.query.channel'(newChannelId) {
        this.selectedChannelId = newChannelId
      }
    },
    mounted() {
      this.selectedChannelId = this.$route.query.channel || null

      if (!this.selectedChannelId && this.channels.length === 1) {
        this.selectChannel(this.channels[0].id)
      }

      window.addEventListener('scroll', this.onScroll.bind(this))

      if (Object.hasOwnProperty.call(window.sessionStorage, 'om_scroll')) {
        this.scroll()
      }
    },

    methods: {
      onStepComponentValidationStateChanged(stepIndex, errors) {
        // remove all old step errors
        this.errors = this.errors.filter(e => e.stepIndex !== stepIndex)

        // add the step errors
        errors.forEach(text => {
          this.errors.push({
            text,
            stepIndex,
          })
        })
      },

      scroll() {
        this.$root.$once('done-loading', () => {
          const scrollValue = JSON.parse(sessionStorage.getItem('om_scroll') || 'null')
          sessionStorage.removeItem('om_scroll')

          if (!isNaN(scrollValue)) {
            window.scroll(0, scrollValue)
          }
        })
      },

      onScroll() {
        sessionStorage.setItem('om_scroll', JSON.stringify(window.scrollY))
      },

      isSelectedChannel(channel) {
        return this.selectedChannel === channel
      },

      async selectChannel(channelId) {
        if (this.selectedChannelId === channelId) {
          return
        }

        const query = { ...this.$route.query }

        if (channelId) {
          query.channel = channelId
        } else {
          delete query.channel
        }

        this.$router.push({
          ...this.$route,
          query,
        })

        trackEvent(EVENT_CHANNEL_SELECTED, {
          channelId,
        })
      },

      async validate() {
        // we can continue here
        this.$loader(true)

        // we don't have any consents to validate at this time,
        // and we will ask for consents inside our OM app later
        if (!this.$refs.steps || null) {
          await this.selectedChannel.saveConsents()
          return
        }

        // validate each step
        let valid = true

        // prepare promises
        const validations = this.$refs.steps.map(step => step.validate())
        const results = await Promise.all(validations)

        // if one step fails, we will stop here
        results.forEach(stepIsValid => {
          if (!stepIsValid) {
            valid = false
          }
        })

        if (!valid) {
          this.$loader(false)
          trackEvent(EVENT_CONSENT_DATA_MISSING)

          return
        }

        // we will call each steps end() method to ensure that everything is okay here
        const callers = []

        this.$refs.steps.forEach(step => {
          callers.push(() => step.end())
        })

        let promise = callers.shift()()

        callers.forEach(caller => {
          promise = promise.then(caller)
        })

        promise.then(() => {
          this.$endOnboarding(this.selectedChannel)
        })
      },
    },
  }
</script>
