<template>
  <div class="mini-channel" @click="$emit('select', channel.id)">
    <div class="mini-channel__logo-container">
      <img :src="channel.getImage()" alt="Channel Logo" />
    </div>

    <div class="mini-channel__details">
      <span class="mini-channel__label">
        {{ label }}
      </span>
      <el-link
        v-if="cancelable"
        class="mini-channel__change-platform"
        @click.stop="$emit('select', null)"
      >
        {{ $t('channelSelection.change') }}
      </el-link>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .mini-channel
    display flex
    align-items center
    box-sizing border-box

    &__logo-container
      margin-right 16px

      img
        width 80px
        display block

    &__details
      span
        display block
        font-size 1.1em

  [dir="rtl"]
    .mini-channel
      &__logo-container
        margin-left 16px
        margin-right 0
</style>

<script>
  import Channel from '@/library/channels/Channel'

  export default {
    name: 'MiniChannel',
    props: {
      channel: {
        type: Channel,
        required: true,
      },
      cancelable: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      label() {
        return this.channel.label || this.channel.getPlatformLabel()
      },
    },
  }
</script>
