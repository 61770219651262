/**
 * The version of the application
 *
 * @type {string}
 */
export const APP_VERSION = process.env.VUE_APP_VERSION

/**
 * How often do we try to fetch the onboarding for QR code based ones.
 * As we are doing things asynchronously we need to wait for an onboarding to be created in our DB
 * specifically if we are using QR Codes.
 *
 * Every try has a 1 second delay
 *
 * @type {number}
 */
export const MAX_FETCH_TRIES = 20

/**
 * Name of the token cookie
 *
 * @type {string}
 */
export const TOKEN_COOKIE_NAME = 'hello_token'

/**
 * Temporary solution for getting Client UUIDs
 */
export const CLIENT_UUIDS = {
  develop: {
    demo: '0cb54e5d-b4f2-4a31-aa22-83f4029e0bbb',
  },
  staging: {
    demo: 'd2b5fdbf-6822-4c8d-baed-cced043285d5',
    bi: '0f5a1e8e-ebda-46c5-a7c1-8a1ecc6be9bf',
    bi_ah: '4ea22668-630f-4b1a-a161-2db0f1c574a9',
  },
  production: {
    bi: 'abcb04ab-cb6e-42e3-8caf-350a2f14e50c',
    bi_ah: 'c7c9dbc5-55de-481b-91c1-393d59344de0',
  },
}
