<template>
  <div class="legal-texts">
    <div v-for="(term, i) in terms" :key="i" class="legal-texts__text">
      <div class="content-padding">
        <h2>{{ term.headline }}</h2>

        <p v-html="term.content" />
      </div>

      <div v-if="term.gathersConsent" class="legal-texts__cb-container">
        <consent-types
          ref="consentType"
          class="content-padding"
          :consent-types="term.consentTypes"
          :agreed="hasAgreedTo(term.id)"
          :required="term.consentRequired"
          @validate="onTermConsentTypesValidationStateChanged(term.id, $event)"
          @details-updated="onConsentTypeDetailsUpdated(term.id, $event)"
        />

        <div class="legal-texts__checkbox-container" :class="{ error: termHasError(term.id) }">
          <el-checkbox
            :label="$t('consents.accept', { term: term.headline })"
            :checked="consents[term.id]"
            size="large"
            @change="toggleConsent(term.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .legal-texts
    width 100%

    &__checkbox-container
      background var(--container-background-color)
      padding 32px
      text-align center

      &.error .el-checkbox /deep/ .el-checkbox__label
        color var(--error)

    .el-checkbox
      padding 8px
      display flex
      align-items center

      /deep/ .el-checkbox__label
        font-size 1.1em
        white-space normal
        color var(--text-color)

    &__buttons
      text-align center
      margin-bottom 30px

    &__text
      margin-bottom 32px

      p
        margin 16px 0
        padding 0

  [dir="rtl"]
    .legal-texts
      .el-checkbox
        /deep/ .el-checkbox__label
          padding-left 0
          padding-right 10px
</style>

<script>
  import channelStepMixin from '@/components/channelStepMixin'
  import ConsentTypes from '@/components/ConsentTypes'
  import { makeConsent } from '@/library/consents'

  export default {
    name: 'LegalTexts',
    components: { ConsentTypes },
    mixins: [channelStepMixin],

    data() {
      return {
        consents: [],
        errors: [],
        termConsentTypeValidationStates: [],
      }
    },

    computed: {
      terms() {
        return this.channel.terms
      },
    },

    watch: {
      errors(errors) {
        // let our parent know what has happened
        // we will have one combined err message for all consents
        errors = errors.length > 0 ? [this.$t('consents.consentMissing')] : []

        // outer validation will only display the texts
        this.setValidationState(this.errors.map(err => err.text))
      },
    },

    created() {
      // setting defaults for all items
      this.terms.forEach(term => {
        term.consentTypes.forEach(consentType => {
          this.consents.push(makeConsent(term.id, consentType.id, false))
        })
      })
    },

    methods: {
      validate() {
        this.errors = []

        this.$refs.consentType.forEach(component => {
          if (typeof component.validate === 'function') {
            component.validate()
          }
        })

        // we will generate specific errors based on our states
        this.terms.forEach(term => {
          // if the consent is required but the user has not yet agreed to it: return the term as faulty
          if (term.consentRequired && !this.hasAgreedTo(term.id)) {
            this.errors.push({
              text: this.$t('consents.requiredTerm', { term: term.headline }),
              termId: term.id,
            })
            return
          }

          // if one of the attached consent types has not passed the validation, return the term as faulty
          if (!this.consentTypesHasPassedValidation(term.id)) {
            this.errors.push({
              text: this.$t('consents.checkYourInput'),
              termId: term.id,
            })
            return
          }
        })

        // may or may not be okay
        return this.errors.length === 0
      },

      termHasError(termId) {
        return this.errors.filter(e => e.termId === termId).length > 0
      },

      /**
       * Checks if the user has agreed (checked the box) for a given term
       *
       * @param termId
       * @returns {boolean}
       */
      hasAgreedTo(termId) {
        // if we find something which says agreed === false, we are not agreeing at all
        return !(this.consents.findIndex(c => c.termId === termId && c.accepted === false) > -1)
      },

      /**
       * Checks if a checkbox should be enabled or not
       *
       * @param termId
       * @returns {boolean|*}
       */
      consentTypesHasPassedValidation(termId) {
        const index = this.termConsentTypeValidationStates.findIndex(s => s.termId === termId)

        if (index === -1) {
          // nothing = everything cool
          return true
        }

        return this.termConsentTypeValidationStates[index].validationState
      },

      /**
       * Executed with consolidated state of all attached consent types for the given term
       *
       * @param termId
       * @param validationState
       */
      onTermConsentTypesValidationStateChanged(termId, validationState) {
        const index = this.termConsentTypeValidationStates.findIndex(s => s.termId === termId)

        if (index > -1) {
          this.termConsentTypeValidationStates.splice(index, 1)
        }

        this.termConsentTypeValidationStates.push({
          termId: termId,
          validationState: validationState,
        })
      },

      /**
       * Toggles a consent for a given text
       *
       * @param termId
       */
      toggleConsent(termId) {
        this.consents.forEach(consent => {
          if (consent.termId === termId) {
            consent.accepted = !consent.accepted
          }
        })
      },

      onConsentTypeDetailsUpdated(termId, { consentTypeId, details }) {
        // find the consent data
        const consent = this.consents.find(c => {
          return c.termId === termId && c.consentTypeId === consentTypeId
        })

        consent.details = details
      },

      /**
       * Is called by the channel selection to perform finalizing actions for this step
       *
       * @returns {Promise<void>}
       */
      end() {
        return this.channel.saveConsents(this.consents)
      },
    },
  }
</script>
