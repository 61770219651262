import store from '@/store'
import { ERROR_ONBOARDING_NOT_FOUND } from '@/errors'
import { loader } from '@/plugins/global-mixins'
import { deleteTokenCookie } from '@/library/cookies'
import { mapGetters } from 'vuex'
import { EVENT_END, trackEvent } from '@/library/tracker'

/**
 * Mixin which loads an onboarding based on the token or redirect the user to status pages
 *
 * @type {*}
 */
const requiresOnboarding = {
  computed: {
    ...mapGetters('onboarding', ['pendingChannel']),
  },

  async beforeRouteEnter(to, from, next) {
    // lets show a UI for loading stuff
    loader(true)

    try {
      // store is doing the magic to load an onboarding
      await store.dispatch('onboarding/fetch')
    } catch (err) {
      // we are ensuring that the cookie is removed – not getting stuck on the page
      deleteTokenCookie()

      // ask the intended component what to do if we have an error
      next(vm => {
        vm.onOnboardingLoadingError()
      })

      return
    } finally {
      // in all cases, hide the loading screen
      loader(false)
    }

    // if the onboarding is already "inactive" – we will be redirected to the
    // thank you page automatically.
    if (!store.getters['onboarding/active'] && to.name !== 'thank-you') {
      return next({ name: 'thank-you' })
    }

    next()
  },

  methods: {
    /**
     * Default functionality on onboarding fetch err
     *
     * @returns {Promise<void>}
     */
    async onOnboardingLoadingError() {
      this.$router
        .push({
          name: 'error',
          params: {
            errorMessage: ERROR_ONBOARDING_NOT_FOUND,
          },
        })
        .catch()
    },

    // can be used to end the onboarding process at a specific step
    async $endOnboarding(channel) {
      this.$loader(true)

      await trackEvent(EVENT_END)
      const result = await channel.endOnboarding(this.$store.getters.token)

      deleteTokenCookie()

      if (typeof result === 'string') {
        window.location.href = result
      } else if (typeof result === 'object' && result !== null) {
        await this.$router.push(result)
      } else if (!!result) {
        await this.$router.push({ name: 'success' })
      }

      return result
    },
  },
}

export default requiresOnboarding
