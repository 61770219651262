import Vue from 'vue'
import { Button, Checkbox, Loading, PageHeader, Link, Input, Form, FormItem } from 'element-ui'
import './style.scss'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.use(Button)
Vue.use(Link)
Vue.use(Loading)
Vue.use(PageHeader)
Vue.use(Checkbox)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
