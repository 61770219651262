/**
 * Helper to keep the structure of a consent straight
 *
 * @param termId
 * @param consentTypeId
 * @param accepted
 * @param details
 * @param reason
 * @returns {*}
 */
export function makeConsent(termId, consentTypeId, accepted = true, details = {}, reason = null) {
  return {
    consentTypeId,
    termId,
    accepted,
    details,
    reason,
  }
}
