<template>
  <div id="app" ref="app">
    <router-view />

    <footer>
      <footer-links />
    </footer>
  </div>
</template>

<script>
  import FooterLinks from '@/components/FooterLinks'
  import { setTheme } from '@/utils/setTheme'

  export default {
    name: 'App',
    components: { FooterLinks },
    created() {
      setTheme()
    },
    mounted() {
      // add support for RTL languages
      const rtlLanguages = ['he']
      const currentLanguage = navigator.language.toLowerCase()

      if (rtlLanguages.some(language => currentLanguage.includes(language))) {
        this.$refs.app.setAttribute('dir', 'rtl')
      }
    },
  }
</script>

<style lang="stylus">
  :root
    --input-text-color rgb(96, 98, 102)
    --input-border-color #DCDFE6
    --input-placeholder-color #c0c4cc
    --input-background-color #ffffff
    --border-color #DCDFE6
    --brand-color1 #1a347f
    --success-light rgb(240, 249, 235)
    --success #67C23A
    --error-light rgb(254, 240, 240)
    --error #F56C6C
    --container-background-color #e0eeff
    --text-color #2c3e50

  html, body
    font-family 'Avenir', Helvetica, Arial, sans-serif
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
    color var(--text-color)
    margin 0
    padding 0
    background-color var(--background-color)

  input
    font-family 'Avenir', Helvetica, Arial, sans-serif

  &.el-input__inner
    background var(--input-background-color)
    border-color var(--input-border-color)

  &.color-block
    background #e0eeff

  section
    padding 16px

  .content-padding
    padding-left 32px
    padding-right 32px

  .el-loading-mask
    background rgba(255, 255, 255, 0.6);
</style>
