import Vue from 'vue'
import router from '@/router'
import { Loading } from 'element-ui'

let _loader = null

/**
 * Simple helper to show and hide a loading screen
 *
 * @param show
 */
export function loader(show) {
  if (!show && _loader) {
    // remove the loader
    _loader.close()
    _loader = null
  } else if (show && !_loader) {
    _loader = Loading.service()
  }
}

/**
 * Simple Vue bridge for some methods
 */
Vue.mixin({
  methods: {
    $fail(err) {
      router.push({
        name: 'error',
        params: {
          errorMessage: err,
        },
      })
    },
    $loader(show) {
      loader(show)
    },
  },
})
