import connectionConfig from '@/library/connectionConfig'
import { CLIENT_UUIDS } from '@/constants'

const getThemeCssCdnUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return '/example_demo_darkmode.css'
  }

  const stage = process.env.VUE_APP_STAGE
  const baseUrl =
    stage === 'production' ? 'https://cdn.one-message.app' : `https://cdn.${stage}.one-message.app`
  const directory = `${CLIENT_UUIDS[stage][connectionConfig.clientName]}`

  return `${baseUrl}/${directory}/hello-app-theme-config/hello-theme.css`
}

const setLogo = () => {
  const hasLogo = getComputedStyle(document.body).getPropertyValue('--logo-url')

  if (!hasLogo) {
    document.body.style.setProperty('--logo-url', `url(${location.origin}/logo/demo.svg)`)
  }
}

export const setTheme = () => {
  // dynamically load theme css from cdn
  const link = document.createElement('link')

  link.href = getThemeCssCdnUrl()
  link.rel = 'stylesheet'
  link.type = 'text/css'

  document.head.appendChild(link)

  // if no logo is set, use the default one
  link.addEventListener('load', setLogo)
  link.addEventListener('error', setLogo)
}
