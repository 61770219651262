import Channel from '@/library/channels/Channel'

export default {
  props: {
    channel: {
      type: Channel,
      required: true,
    },
  },
  methods: {
    setValidationState(errors = []) {
      this.$emit('validation-state-changed', errors)
    },
  },
}
