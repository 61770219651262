import store from '@/store'
import { api } from '@/plugins/axios'

export const EVENT_PAGE_VIEW = 'onboarding_page_view'
export const EVENT_END = 'onboarding_end'
export const EVENT_CHANNEL_SELECTED = 'onboarding_channel_selected'
export const EVENT_CONSENT_DATA_MISSING = 'onboarding_consent_data_missing'
export const EVENT_CONSENTS_STORED = 'onboarding_consents_stored'
export const EVENT_CALLBACK_INVALID = 'onboarding_callback_invalid'
export const EVENT_CALLBACK_API_ERROR = 'onboarding_callback_invalid_api_response'
export const EVENT_CALLBACK_DATA_VERIFIED = 'onboarding_callback_data_verified'
export const EVENT_LAZY_START_ERROR = 'onboarding_lazy_start_error'

export async function trackEvent(eventType, data = null) {
  const onboardingId = store.getters['onboarding/id']

  if (!onboardingId) {
    console.warn('Onboarding not yet loaded.')
    return
  }

  const parameters = new URLSearchParams()

  if (data) {
    parameters.append('data', btoa(JSON.stringify(data)))
  }

  parameters.append('trackedAt', Math.floor(new Date().valueOf() / 1000))

  let res = null

  try {
    res = await api.post(`track/onboarding/${onboardingId}/${eventType}?` + parameters.toString())
  } catch (err) {
    console.error('Track Failed', err)
  }

  return res
}
