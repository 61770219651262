import Vue from 'vue'
import './plugins/element/element.js'
import './plugins/vue-tel-input'
import './plugins/global-mixins'

import App from './App'
import router from './router'
import store from './store'
import i18n from '@/plugins/i18n'
import { APP_VERSION } from '@/constants'

console.log(`Hello App v${APP_VERSION}`)

export const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
