<template>
  <div class="consent-types">
    <div v-for="handler in handlers" :key="handler.id">
      <div
        :is="handler.constructor.component()"
        v-if="handler.constructor.component()"
        ref="consentTypeHandler"
        :handler="handler"
        :agreed="agreed"
        :required="required"
        class="consent-types__handler"
        @validate="onHandlerHasValidated(handler.id, $event)"
        @update="onHandlerHasDetailsUpdated(handler.id, $event)"
      />
    </div>
  </div>
</template>

<style lang="stylus">
  .consent-types
    &__handler
      margin-bottom 24px

  .el-form-item__label
      color var(--text-color)
</style>

<script>
  import ConsentType from '@/library/consentTypes/ConsentType'

  const loadConsentTypeHandler = async function (consentType) {
    if (!consentType.handler) {
      return new ConsentType(consentType.id)
    }

    const lowercaseName = consentType.handler
    const uppercaseName = lowercaseName[0].toUpperCase() + lowercaseName.slice(1) + 'ConsentType'

    return import(`@/library/consentTypes/${lowercaseName}/${uppercaseName}.js`)
      .then(module => {
        return new module.default(consentType.id)
      })
      .catch(err => {
        return new ConsentType(consentType.id)
      })
  }

  export default {
    name: 'ConsentTypes',
    props: {
      consentTypes: {
        type: Array,
        required: true,
      },
      agreed: {
        type: Boolean,
        required: true,
      },
      required: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        handlers: [],
        validationStates: [],
      }
    },

    async created() {
      const promises = this.consentTypes.map(loadConsentTypeHandler)
      this.handlers = await Promise.all(promises)
    },

    methods: {
      validate() {
        if (!Array.isArray(this.$refs.consentTypeHandler)) {
          return
        }

        this.$refs.consentTypeHandler.forEach(component => {
          if (typeof component.validate === 'function') {
            component.validate()
          }
        })
      },

      onHandlerHasValidated(handlerId, validationState) {
        const index = this.validationStates.findIndex(s => s.handlerId === handlerId)

        if (index > -1) {
          this.validationStates.splice(index, 1)
        }

        this.validationStates.push({
          handlerId,
          validationState,
        })

        const hasInvalidValidationState =
          this.validationStates.findIndex(s => !s.validationState) > -1

        // if at least one consent type has an invalid state, we will pass false, if not, we will pass true
        this.$emit('validate', !hasInvalidValidationState)
      },

      onHandlerHasDetailsUpdated(handlerId, details = {}) {
        // pass it through
        this.$emit('details-updated', {
          consentTypeId: handlerId,
          details,
        })
      },
    },
  }
</script>
