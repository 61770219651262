<template>
  <div>
    <p>
      {{ $t('success.message') }}
    </p>
  </div>
</template>

<script>
  import { deleteTokenCookie } from '@/library/cookies'

  export default {
    name: 'Success',
    mounted() {
      this.$loader(false)
      deleteTokenCookie()
    },
  }
</script>
