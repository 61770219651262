<template>
  <div class="logo-container">
    <div class="logo-container__logo" role="img" aria-label="Logo" @click="openWebsite"></div>

    <template v-if="hasChildRoutes">
      <router-view />
    </template>
    <template v-else>
      <slot />
    </template>

    <el-button v-if="shouldGuideToCorporateWebsite" @click="openWebsite">
      {{ $t('common.visit') }}
    </el-button>
  </div>
</template>

<style lang="stylus" scoped>
  .logo-container
    padding 64px 16px
    text-align center
    display flex
    flex-direction column
    align-items center

    &__logo
      background-image: var(--logo-url);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 50px;
      width: 160px;
      margin-top: 30px;

    /deep/ p
      margin 64px 0
      padding 0 32px
</style>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'LogoContainer',
    props: {
      hasChildRoutes: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapGetters(['clientName']),

      shouldGuideToCorporateWebsite() {
        return this.$route.meta.guidesToWebsite
      },
    },
    methods: {
      openWebsite() {
        if (this.shouldGuideToCorporateWebsite) {
          location.href = this.$t('common.website')
        }
      },
    },
  }
</script>
