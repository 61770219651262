import { makeConsent } from '@/library/consents'
/**
 * A Channel Implementation
 * The job of a channel implementation is to link a user to a given channel.
 * Aka Onboard a user to a channel.
 *
 * Once a user has selected a channel – the process starts and the channels _link_ method
 * is executed.
 *
 * If a callback is involved, the channel has to implement
 *
 */
import { api } from '../../plugins/axios'
import store from '@/store'
import { EVENT_CALLBACK_API_ERROR, EVENT_CONSENTS_STORED, trackEvent } from '@/library/tracker'

const ONBOARDING_FLOW_CONSENTS_FIRST = 'consents_first'
const ONBOARDING_FLOW_CONSENTS_WITH_MSG = 'consents_with_message'

export default class Channel {
  /**
   * Creates a new channel instance
   *
   * @param echoChannelId
   * @param channelDetails
   * @param terms
   * @param label
   * @param onboardingFlowType
   * @param imprintConfig
   */
  constructor(
    echoChannelId,
    channelDetails,
    terms,
    label,
    onboardingFlowType = null,
    imprintConfig = null
  ) {
    this._id = echoChannelId
    this._details = channelDetails
    this._label = label
    this._terms = terms
    this._onboardingFlowType = onboardingFlowType || ONBOARDING_FLOW_CONSENTS_FIRST

    /**
     * the imprint
     * @type {url: string, label: string}
     */
    this.imprint = imprintConfig
  }

  /**
   * Checks if an imprint is available
   * @return {boolean}
   */
  hasImprint() {
    return this.imprint !== null
  }

  /**
   * The id of the channel
   *
   * @returns {Number}
   */
  get id() {
    return this._id
  }

  /**
   * The name of the platform
   *
   * @returns {String}
   */
  get name() {
    return this.getPlatformLabel() || this.constructor.name
  }

  /**
   * The list of terms for this channel
   *
   * @return {*[]}
   */
  get terms() {
    return this._terms
  }

  /**
   * The label of this channel
   *
   * @return {String}
   */
  get label() {
    return this._label
  }

  /**
   * Returns the friendly platform label
   *
   * @returns {String}
   */
  getPlatformLabel() {
    throw new Error('getPlatformLabel lacks implementation')
  }

  /**
   * Returns the type of onboarding flow
   *
   * @returns {*}
   */
  getTypeOfOnboardingFlow() {
    return this._onboardingFlowType
  }

  /**
   * Returns the image for the platform
   *
   * @returns {*}
   */
  getImage() {
    throw new Error('getImage lacks implementation')
  }

  /**
   * Can be used to add a view to the selection
   *
   * @returns {Promise<[]>}
   */
  async getChannelViews() {
    return Promise.resolve([])
  }

  /**
   * If a channel overwrites this method and returns linking information instead of `null`, the hello app
   * will use the details during the linking call instead of using a dedicated call (the after consent call).
   *
   * This reduces the number of calls required and speeds up the process...
   *
   * @return {Promise<unknown>}
   */
  async linkingDetailsForConsentCall() {
    return Promise.resolve(null)
  }

  /**
   * Is executed right after the backend has stored the consents.
   * Can be used by the channel to do some additional stuff before we jump into the client application.
   *
   * Is called after consents are being sent
   *
   * @param {String} token
   * @return {Promise<void>}
   */
  async afterConsents(token) {
    console.log('afterConsents not implemented by channel')
    // can be implemented
  }

  /**
   * Callback Handler (optional)
   * If some request lands on /callback, this one will be executed
   *
   * @param {String} token
   * @param {URLSearchParams} urlSearchParams
   * @return {Promise<void>}
   */
  async callbackReceived(token, urlSearchParams) {
    // can be implemented by channel
  }

  /**
   * Stores all consents to the backend
   *
   * @param consents
   * @returns {Promise<void>}
   */
  async saveConsents(consents = []) {
    if (consents.length === 0) {
      // populate with defaults from consent types if possible
      this.terms.forEach(t => {
        t.consentTypes.forEach(ct => {
          if (ct.defaults !== null) {
            consents.push(
              makeConsent(
                t.id,
                ct.id,
                ct.defaults.accepted,
                ct.defaults.details,
                ct.defaults.reason
              )
            )
          }
        })
      })
    }

    // maybe there are information we can use for linking...
    const linkingDetails = await this.linkingDetailsForConsentCall()

    // save this with the backend
    await store.dispatch('onboarding/saveConsents', {
      channelId: this.id,
      consents,
      linkingDetails,
    })

    await trackEvent(EVENT_CONSENTS_STORED)

    // the channel may have to prepare something before continuing
    return await this.afterConsents(store.getters.token)
  }

  /**
   * Needs to be implemented by channel in order to finalize the onboarding
   *
   * @param {String} token
   * @return {Promise<void>}
   */
  async endOnboarding(token) {
    throw new Error('endOnboarding lacks implementation')
  }

  /**
   * Helper to verify information with the API.
   *
   * @param {String} token
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  async callBackendChannelLinking(token, params) {
    try {
      return await api.post(`/onboardings/${token}/link`, params)
    } catch (err) {
      await trackEvent(EVENT_CALLBACK_API_ERROR, { params: params })
      throw err
    }
  }

  /**
   * Calls a delegated method in the backend implementation of the channel
   *
   * @param method
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async delegateCall(method, data) {
    return api.post(`/onboardings/${store.getters.token}/delegate/${this.id}/${method}`, data)
  }

  /**
   * May return an Url which will be used within the thank you page
   *
   * @returns {<null|string>}
   */
  async generateThankYouUrl() {
    return null
  }

  /**
   * Check if Hello app handles consents or if we collect it via messages
   * 
   * @return {boolean}
   */
  shouldGatherConsentsWithHelloApp() {
    // if we collect the consent with a message – lets move on
    if (this.getTypeOfOnboardingFlow() === ONBOARDING_FLOW_CONSENTS_WITH_MSG) {
      return false
    }

    // if we have defaults given, then no need to do a UI
    const countOfTermsWithoutDefaults = this.terms.filter(t => {
      return t.consentTypes.filter(ct => ct.defaults === null).length > 0
    }).length

    return countOfTermsWithoutDefaults > 0
  }
}
