import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/locales/common'
import connectionConfig from '@/library/connectionConfig'
import deepmerge from 'deepmerge'

// we are building a specific i18n config from the common config
// and the specific config of a client
async function _loadMessages() {
  try {
    const client = connectionConfig.clientName
    const clientSpecificI18n = (await import(`../locales/clients/${client}.json`)).default

    // if we have nothing here, then we are done
    if (!clientSpecificI18n) {
      return messages
    }

    // lets now integrate the client specific changes into our default messages
    Object.keys(messages).forEach(key => {
      // check if we have a "*" key which will be integrate in all languages
      if (Object.hasOwnProperty.call(clientSpecificI18n, '*')) {
        messages[key] = deepmerge(messages[key], clientSpecificI18n['*'])
      }

      // check if we have a language specific object in our clients i18n definition
      if (Object.hasOwnProperty.call(clientSpecificI18n, key)) {
        messages[key] = deepmerge(messages[key], clientSpecificI18n[key])
      }
    })
  } catch (err) {
    console.debug(err)
    console.debug(`No i18n file for client [${connectionConfig.clientName}] found`)
  }

  return messages
}

// we are preparing the i18n setup sync to ensure that we can build our app synchronously
Vue.use(VueI18n)

const params = new URLSearchParams(location.search)
const locale = params.get('locale') || navigator.language

console.log('Using locale', locale)

// it will contain the default system wide locales by default
const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  silentTranslationWarn: false,
  messages,
})

// we will only load the locales async and update the i18n afterwards
_loadMessages().then(m => {
  Object.keys(m).forEach(key => {
    i18n.setLocaleMessage(key, m[key])
  })

  document.title = i18n.t('title')
})

export default i18n
