import Vue from 'vue'
import VueRouter from 'vue-router'
import LogoContainer from '@/views/LogoContainer'
import SelectChannel from '@/views/SelectChannel'
import Success from '@/views/Success'
import LoadToken from '@/views/LoadToken'
import { EVENT_PAGE_VIEW, trackEvent } from '@/library/tracker'

Vue.use(VueRouter)

const routes = [
  {
    path: '/select-channel',
    name: 'select-channel',
    component: SelectChannel,
  },
  {
    path: '/',
    component: LogoContainer,
    children: [
      // starting a new onboarding lazily
      {
        path: 'start/:connectionIdentifier/:accountReference',
        name: 'starting',
        component: () => import(/* webpackChunkName: "callback" */ '../views/Starting'),
        props: route => ({
          connectionIdentifier: route.params.connectionIdentifier,
          accountReference: route.params.accountReference,
        }),
        meta: {
          guidesToWebsite: true,
        },
      },

      // displaying a "generic" error
      {
        path: 'error',
        name: 'error',
        props: true,
        component: () => import(/* webpackChunkName: "error" */ '../views/Error'),
        meta: {
          guidesToWebsite: true,
        },
      },

      // external party callback (e.g. LINE Callback)
      {
        path: 'callback',
        name: 'callback',
        component: () => import(/* webpackChunkName: "callback" */ '../views/Callback'),
      },

      // generic success message
      {
        path: 'success',
        name: 'success',
        props: true,
        component: Success,
        meta: {
          guidesToWebsite: true,
        },
      },

      // thanks message after consent collection via message
      {
        path: 'thank-you',
        name: 'thank-you',
        component: () => import(/* webpackChunkName: "thank-you" */ '../views/ThankYou'),
      },

      {
        path: '/:onboardingToken',
        component: LoadToken,
      },

      // default index
      {
        path: '',
        name: 'index',
        redirect: {
          name: 'select-channel',
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeResolve((to, from, next) => {
  if (from.name !== to.name) {
    trackEvent(EVENT_PAGE_VIEW, {
      from: from.name,
      to: to.name,
    })
  }
  next()
})

export default router
