import clientsConfig from '../../clients.conf.js'

class ConnectionConfig {
  constructor(hostMap) {
    this._hostMap = hostMap
    this._computedConfig = null
  }

  _computeConfig() {
    const host = window.location.hostname.toLowerCase()

    for (let i in this._hostMap) {
      const mapEntry = this._hostMap[i]
      const matchResult = new RegExp(mapEntry.pattern).exec(host)

      if (!matchResult) {
        continue
      }

      let urlClientName = mapEntry.config.clientName

      // if there is no static client name defined, try to use it from the matched results
      if (!urlClientName && matchResult[1]) {
        urlClientName = matchResult[1]
      }

      return {
        clientName: urlClientName.replace(/-/g, '_'),
        apiUrl:
          location.protocol + '//' + mapEntry.config.apiUrl.replace('%client%', urlClientName),
        callbackUrl:
          location.protocol + '//' + mapEntry.config.callbackUrl.replace('%client%', urlClientName),
      }
    }

    return null
  }

  getConfig() {
    if (!this._computedConfig) {
      this._computedConfig = this._computeConfig()
    }

    if (!this._computedConfig) {
      document.write(`No Config found for Hostname ${window.location.hostname}`)
      throw new Error(`No Config found for Hostname ${window.location.hostname}`)
    }

    console.log(`Configuration Loaded`, this._computedConfig)

    return this._computedConfig
  }
}

export default new ConnectionConfig(clientsConfig.hostMap).getConfig()
