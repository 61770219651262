import { api } from '../plugins/axios'
import { MAX_FETCH_TRIES } from '@/constants'
import { root } from '../../.eslintrc'

const state = () => {
  return {
    id: null,
    channels: [],
    token: null,
    status: null,
    pendingChannelId: null,
    active: false,
  }
}

const getters = {
  id: state => state.id,
  channels: state => state.channels,
  token: state => state.token,
  pendingChannelId: state => state.pendingChannelId,
  pendingChannel(state) {
    return state.channels.find(c => c.id === state.pendingChannelId)
  },
  active(state) {
    return state.active
  },

  footerLinks(state) {
    return state.channels.reduce((memo, channel) => {
      if (!channel.hasImprint()) {
        return memo
      }

      const idx = memo.findIndex(i => i.label === channel.imprint.label)

      if (idx > -1) {
        memo.splice(idx, 1)
      }
      memo.push({ ...channel.imprint })

      return memo
    }, [])
  },
}

const mutations = {
  addChannel(state, channel) {
    state.channels.push(channel)
  },

  loadFromResponse(state, data) {
    // channels won't be directly merged
    delete data.channels

    // merge into our state
    Object.assign(state, data)
  },
}

const actions = {
  async start({ dispatch, getters }, { connectionIdentifier, accountReference }) {
    const url = `/onboardings/${window.location.search}`

    const res = await api.post(url, {
      connectionIdentifier,
      accountReference,
    })

    await dispatch('loadFromResponse', res.data)

    return getters.token
  },

  async loadFromResponse({ commit, dispatch }, response) {
    commit('loadFromResponse', {
      id: response.id,
      token: response.token,
      pendingChannelId: response.pendingChannelId,
      status: response.status,
      active: response.active,
    })

    const promises = response.channels.map(channel => dispatch('createChannel', channel))
    await Promise.all(promises)

    // store the token in common state
    commit('SET_TOKEN', response.token, { root: true })
  },

  /**
   * Fetches onboarding details
   *
   * @param dispatch
   * @param token
   * @return {Promise<AxiosResponse<any>>}
   */
  async fetch({ dispatch, commit, getters, rootGetters }) {
    // already fetched?
    if (getters.token) {
      return getters.channels
    }

    // get the token
    const token = await dispatch('loadToken', null, { root: true })

    if (!token) {
      return Promise.reject(new Error('Token not found'))
    }

    return new Promise((resolve, reject) => {
      let tries = 1

      const fetcher = async function () {
        if (tries > MAX_FETCH_TRIES) {
          reject(new Error('Max tries for fetching Onboarding exceeded'))
          return
        }

        let response = null

        try {
          response = await api.get(`/onboardings/${token}`)
        } catch (err) {
          if (err.isAxiosError) {
            // retry
            tries++

            window.setTimeout(fetcher, 1000)
          }

          throw err
        }

        await dispatch('loadFromResponse', response.data)
        resolve()
      }

      fetcher()
    })
  },

  /**
   * Creates a channel model from the json
   *
   * @param commit
   * @param channelData
   * @return {Promise<*>}
   */
  async createChannel({ commit }, channelData) {
    // load the class dynamically
    const { default: Channel } = await import(
      `../library/channels/${channelData.handler.toLowerCase()}/${channelData.handler}.js`
    )

    const c = new Channel(
      channelData.id,
      channelData.options,
      channelData.terms,
      channelData.public_label,
      channelData.onboarding_flow_type || null,
      channelData.imprint || null
    )

    // add it
    commit('addChannel', c)

    return c
  },

  /**
   * Stores the consents to the backend
   *
   * @param commit
   * @param consents
   * @param channelId
   * @return {Promise<void>}
   */
  async saveConsents({ commit, rootGetters }, payload) {
    const token = rootGetters.token

    return api
      .patch(`onboardings/${token}`, payload)
      .then(res => {
        commit('loadFromResponse', res.data)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
