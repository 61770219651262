import Vue from 'vue'
import Vuex from 'vuex'
import onboarding from './onboarding'
import common from './common'

Vue.use(Vuex)

const store = new Vuex.Store({
  ...common,
  modules: {
    onboarding,
  },
})

// debug purposes
window.__store = store

export default store
