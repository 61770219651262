/**
 * Tries to get the token from the cookie
 *
 * @returns {string|null}
 */
import { TOKEN_COOKIE_NAME } from '@/constants'

/**
 * Returns the token from the cookie
 *
 * @returns {string|null}
 */
export function getTokenFromCookie() {
  try {
    return document.cookie
      .split('; ')
      .find(row => row.startsWith(TOKEN_COOKIE_NAME))
      .split('=')[1]
  } catch (err) {
    return null
  }
}

/**
 * Stores the token to a cookie for one day
 */
export function storeTokenToCookie(token) {
  const expiryDate = new Date()
  expiryDate.setDate(expiryDate.getDate() + 1)

  document.cookie = `${TOKEN_COOKIE_NAME}=${token}; Expires=${expiryDate.toUTCString()};`
}

/**
 * Deletes the token cookie
 */
export function deleteTokenCookie() {
  document.cookie = `${TOKEN_COOKIE_NAME}=; Expires=Thu, 01 Jan 1970 00:00:00 UTC`
}
